import './App.css';
import  { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom"
import Home from './Home';
import Login from './Login';
// import Homenew from './Homenew';
function App() {
  return (
    <>
     <Router>
        <Routes basename="/home">
          <Route path="/login/:user_id/:token/:type/:rejoin" element={<Login />} />
          <Route path="/home" element={<Home/>} />
          {/* <Route path="/homenew" element={<Homenew/>} /> */}
        </Routes>
      </Router>
    </>
  );
}
export default App;
