import React from 'react';
import { CSSTransition } from 'react-transition-group';

function PlayerStatusNotificationBox({index, isActive, content, endTransition}) {
    return(
        <CSSTransition 
            in={isActive} 
            timeout={{
                appear: 0,
                enter: 0,
                exit: 1250,
               }}
            classNames="transitionable-actionBox" 
            onEntered={() => {
              setTimeout(() => {
                endTransition(index)
              }, 25)
            }}
        >
           
            <div className="actionBox">
            <div className="betvalue"><h6>0.5</h6></div>
            {`${content}`}
            </div>
          
        </CSSTransition>
    )
}

export default PlayerStatusNotificationBox;