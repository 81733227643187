import React, { Component } from 'react';
import { useState, useEffect, useRef } from 'react';
import { autoLogin } from "./components/service/Service"
import { useNavigate, useParams, useLocation } from 'react-router-dom';

function Login() {
    const nav = useNavigate();

    const { user_id, token, type, rejoin } = useParams();
     useEffect(() => {
        userLogin({"user_id":user_id,"token":token,"type":type,"rejoin":rejoin})

    
      }, [])

    const userLogin = async(e) => {
        let data = await autoLogin(e);
        console.log(data);

        if(data.response !== false){
            nav('/login')
        }else{
            //nav('/accessdined')
            alert ("Worang token")
        }
      }
  return (
    <div>
       <h1>Login</h1>
      <p>User ID: {user_id}</p>
      <p>Token: {token}</p>
      <p>Type: {type}</p>
      <p>Rejoin: {rejoin}</p>
    </div>
  )
}

export default Login
