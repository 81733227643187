import axios from 'axios'
// import React from "react";
 //import  { useState, useEffect, useRef } from 'react';

const URL ="https://rummy.anshuwap.com/admincodeV1/myapi"
const headers = {
    'Content-Type': 'multipart/form-data'
}

// let user_id = "";
if(localStorage.getItem("user")!=="undefined") {
    const userData = localStorage.getItem("user")!==undefined && JSON.parse(localStorage.getItem("user"));
    console.log(userData);
   
}

//Login
export const autoLogin = async(profile) => {
    const data = {
        user_id:profile.user_id,
        token:profile.token,
        type:profile.type,
        rejoin:profile.rejoin
    };
    return await axios
      .post(URL + "/Poker/start_game", data, {headers: headers})
      .then((user) => {
        //localStorage.clear();
        if(user.data.data !== ""){
            localStorage.setItem("user", JSON.stringify(user.data));
            console.log(user.data)
        }
        return user.data;
      });
  }




//Get Table
// 
export const get_table = async(user_id, token, type, table_id, no_of_players) => {
    const data ={
        user_id:user_id,
        no_of_players:no_of_players, 
        type:type,
        table_id:table_id,
        token:token

    };
    return await axios.post(URL+"/Poker/get_table", data, {headers: headers})
}

//Get status
export const status = async (boot_value, user_id, type, table_id, game_id, token, card_opened) => {
    const data = {
      user_id: user_id,
      type: type,
      table_id: table_id,
      token: token,
      game_id: game_id,
      boot_value: boot_value,
      card_opened: card_opened
    };
  
    try {
      const response = await axios.post(URL + "/Poker/status", data, { headers: headers });
      const responseData = response.data;
  console.log(responseData);
      // Compare user_id with the response data
      console.log(responseData.table_users[0].user_id)
      if (responseData.table_users[0].user_id
        == user_id) {
        // User ID matches
        console.log("User ID matches:", user_id);
      } else {
        // User ID doesn't match
        console.log("User ID doesn't match:", user_id);
      }
  
      return responseData;
    } catch (error) {
      // Handle error here
      console.error("Error in status request:", error);
      throw error;
    }
  };
  

//  start game
export const start_game = async(rejoin, user_id,type, token  ) => {
    const data ={
        user_id:user_id,
        type:type,
        
        token:token,
        rejoin:rejoin
       
    }
    
    
return await axios.post(URL+"/Poker/start_game", data,{headers: headers})}
